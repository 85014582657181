body, html {
    height: 100%;
    width: 100%;
}

.main {
    background:  url("../editable-stuff/background.png");

    height: 100%;
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mainheading {
    background-position: top;
    background-repeat: no-repeat;
    background-size: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 600px;
}


.form {
    position: relative;
}