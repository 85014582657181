:root {
    --arrow-left: 80%;
  }
  @media (max-width: 640px) {
    .tutorial-box {
        position: absolute;
        max-width: 600px;
        height: auto;
        border: 2px solid #000;
        background-color: #fff;
        padding: 20px;
        margin-top: 130px;
        z-index: 101;
        font-size: 20px;
        box-sizing: border-box;
        display: none;
    }
    .tutorial-box-1
    {
    margin-top: 80px !important;
    }
    .tutorial-box-2
    {
    margin-left:  20px !important;
    margin-top :260px !important;
    }
   
    .tutorial-box-nav {
        position: absolute;
        max-width: 600px;
        height: auto;
        border: 2px solid #000;
        background-color: #fff;
        padding: 20px;
        margin-top: 100px;
        margin-left: 5px;
        z-index: 101;
        font-size: 20px;
        box-sizing: border-box;
        display: none;
    }

    .tutorial-box-5 {
        position: absolute;
        max-width: 600px;
        height: auto;
        border: 2px solid #000;
        background-color: #fff;
        padding: 20px;
        margin-top: 240px;
        z-index: 101;
        font-size: 20px;
        box-sizing: border-box;
        display: none;
    }
    .tutorial-box-6
    {
        margin-top: 1375px !important;
    }
    .tutorial-box-8 
    {
        margin-left :20px !important;
        margin-top :1500px !important;
    }
    .tutorial-box-7
    {
        display: block;
        margin-top:1800px !important;
    }
    .tutorial-box-9
    {
       
        bottom: 0px !important;
    }
    }


    @media (min-width: 641px) {
     
        .tutorial-box-6
        {
          margin-left :30px !important;
          margin-top :790px !important;
        }
        .tutorial-box-7
        {
            display: block;
            margin-top:1300px !important;
        }
        .tutorial-box-9
        {
            margin-top :1750px !important;

        }
        .tutorial-box-8 
        {
          margin-left :130px !important;
          margin-top :875px !important;
        }
        .tutorial-box-5 {
            position: absolute;
            max-width: 600px;
            height: auto;
            border: 2px solid #000;
            background-color: #fff;
            padding: 20px;
            margin-top: 160px;
            margin-left: 60px;
            z-index: 101;
            font-size: 20px;
            box-sizing: border-box;
            display: none;
            
        }
.tutorial-box {
    position: absolute;
    max-width: 600px;
    height: auto;
    border: 2px solid #000;
    background-color: #fff;
    padding: 20px;
    margin-top: 90px;
    margin-left: 470px;
    z-index: 101;
    font-size: 20px;
    box-sizing: border-box;
    display: none;
}

.tutorial-box-1
{
   margin-left :650px  !important;;
}

.tutorial-box-2
{
   margin-left:  20px !important;
   margin-top :160px !important;
}

.tutorial-box-nav-1
{
  margin-left : 155px !important;
}
.tutorial-box-nav {
    position: absolute;
    max-width: 600px;
    height: auto;
    border: 2px solid #000;
    background-color: #fff;
    padding: 20px;
    margin-top: 90px;
    margin-left: 80px;
    z-index: 101;
    font-size: 20px;
    box-sizing: border-box;
    display: none;
}

}

.tutorial-box:before {
    position: absolute;
    width: 40px;
    height: 40px;
    border-top: 2px solid #000;
    border-left: 2px solid #000;
    border-right: 0px solid #000;
    border-bottom: 0px solid #000;
    bottom: 100%;
    left: var(--arrow-left);
    content: '';
    transform: rotate(45deg);
    margin-bottom: -18px;
    background-color: #fff;
}


.tutorial-box-nav:before {
    position: absolute;
    width: 40px;
    height: 40px;
    border-top: 2px solid #000;
    border-left: 2px solid #000;
    border-right: 0px solid #000;
    border-bottom: 0px solid #000;
    bottom: 100%;
    left: 77px;
    content: '';
    transform: rotate(45deg);
    margin-bottom: -18px;
    background-color: #fff;
    z-index: 101;
}

.tutorial-box-congrats {
    position: absolute;
    max-width: 600px;
    height: auto;
    border: 2px solid #000;
    background-color: #fff;
    padding: 20px;
    left: 40%;
    top: 20%;
    z-index: 101;
    font-size: 20px;
    box-sizing: border-box;
    display: none;
}

.overlay {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: #000;
    opacity: .6;
    z-index: 98;
    display: none;
}
